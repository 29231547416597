<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="单位名称">
          <el-input v-model="searchForm.unitName" autocomplete="off" size="small" placeholder="请输入单位名称" clearable style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="设备特征码">
          <el-input v-model="searchForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入设备特征码" clearable style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-select v-model="searchForm.typeId" placeholder="请选择设备类型" size="small" clearable style="width:150px">
            <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="searchForm.status">
            <el-radio v-for="item in [{id:0,label:'离线'},{id:1,label:'在线'}]" :key="item.key" :label="item.id">
              {{item.label}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增设备</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="content">
      <el-table :data="deviceList" stripe style="width: 100%" height="100%" v-loading="loading1">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="设备类型" :label-width="formLabelWidth">
                    <span>{{props.row.typeName}}</span>
                  </el-form-item>
                  <el-form-item label="所在单位" :label-width="formLabelWidth">
                    <span>{{props.row.unitName}}</span>
                  </el-form-item>
                  <el-form-item label="安装建筑物" :label-width="formLabelWidth">
                    <span>{{props.row.installName}}</span>
                  </el-form-item>
                  <el-form-item label="联系电话" :label-width="formLabelWidth">
                    <span>{{props.row.contactPhone}}</span>
                  </el-form-item>
                  <el-form-item label="状态" :label-width="formLabelWidth">
                    <el-tag type="success" size="normal" v-show="((new Date().getTime()-new Date(props.row.lastContactTime).getTime())<72*60*60*1000)&&props.row.typeId!==8&&props.row.typeId!==17">在线</el-tag>
                    <el-tag type="danger" size="normal" v-show="((new Date().getTime()-new Date(props.row.lastContactTime).getTime())>72*60*60*1000)&&props.row.typeId!==8&&props.row.typeId!==17">离线</el-tag>
                    <el-tag type="success" size="normal" v-show="props.row.typeId===8&&props.row.status===1">在线</el-tag>
                    <el-tag type="danger" size="normal" v-show="props.row.typeId===8&&props.row.status===0">离线</el-tag>
                    <el-tag type="success" size="normal" v-show="props.row.typeId===17">在线</el-tag>
                  </el-form-item>
                  <el-form-item label="图片" :label-width="formLabelWidth">
                    <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="安装时间" :label-width="formLabelWidth">
                    <span>{{props.row.installTime|timeFormat}}</span>
                  </el-form-item>
                  <el-form-item label="设备特征码" :label-width="formLabelWidth">
                    <span>{{props.row.equipmentCode}}</span>
                  </el-form-item>
                  <el-form-item label="基站特征码" :label-width="formLabelWidth">
                    <span>{{props.row.stationCode}}</span>
                  </el-form-item>
                  <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
                    <span>{{props.row.lastContactTime|timeFormat}}</span>
                  </el-form-item>
                  <el-form-item label="描述" :label-width="formLabelWidth">
                    <span>{{props.row.description}}</span>
                  </el-form-item>
                  <el-form-item label="经纬度" :label-width="formLabelWidth">
                    <span>{{props.row.lng}} # {{props.row.lat}}</span>
                    <el-button type="text" size="default" @click="open('map',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                  </el-form-item>
                  <el-form-item label="地址" :label-width="formLabelWidth">
                    <span>{{props.row.address}}</span>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="installName" label="安装建筑物" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="stationCode" label="基站特征码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip>
          <template slot-scope="props">
            <el-tag type="success" size="normal" v-show="((new Date().getTime()-new Date(props.row.lastContactTime).getTime())<72*60*60*1000)&&props.row.typeId!==8&&props.row.typeId!==17">在线</el-tag>
            <el-tag type="danger" size="normal" v-show="((new Date().getTime()-new Date(props.row.lastContactTime).getTime())>72*60*60*1000)&&props.row.typeId!==8&&props.row.typeId!==17">离线</el-tag>
            <el-tag type="success" size="normal" v-show="props.row.typeId===8&&props.row.status===1">在线</el-tag>
            <el-tag type="danger" size="normal" v-show="props.row.typeId===8&&props.row.status===0">离线</el-tag>
            <el-tag type="success" size="normal" v-show="props.row.typeId===17">在线</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.installTime|timeFormat}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteDevice(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 设备弹窗 -->
    <el-dialog :title="optFlag?'添加设备':'修改设备'" :visible.sync="dialog1" width="55%" @close="clear" top="5%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="deviceForm" ref="deviceForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="设备类型" prop="typeId" :label-width="formLabelWidth2">
              <el-select v-model="deviceForm.typeId" placeholder="请选择设备类型" clearable>
                <el-option v-for="item in typeList" :key="item.value" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
              <el-input v-model="deviceForm.address" autocomplete="off" placeholder="请输入单位地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所在单位" :label-width="formLabelWidth2">
              <!-- <el-autocomplete class="inline-input" v-model="deviceForm.unitName" value-key="unitName" :fetch-suggestions="querySearch" placeholder="请输入所在单位" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete> -->
              <el-select v-model="deviceForm.unitId" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod">
                <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
                  <span style="float: left">{{ item.unitName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="安装建筑物" :label-width="formLabelWidth">
              <!-- <el-autocomplete class="inline-input" v-model="deviceForm.installName" value-key="buildingName" :fetch-suggestions="querySearch1" placeholder="请输入安装建筑物" :trigger-on-focus="false" @select="handleSelect1"></el-autocomplete> -->
              <el-select v-model="deviceForm.installId" placeholder="请选择安装建筑物" clearable filterable remote :remote-method="remoteMethod2">
                <el-option v-for="(item,i) in buildingOptions" :key="i" :label="item.buildingName" :value="item.id">
                  <span style="float: left">{{ item.buildingName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所在消防部门" prop="fireUnitId" :label-width="formLabelWidth2">
              <el-select v-model="deviceForm.fireUnitId" placeholder="请选择所在消防部门" clearable filterable>
                <el-option v-for="item in fireUnitList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="设备特征码" prop="equipmentCode" :label-width="formLabelWidth">
              <el-input v-model="deviceForm.equipmentCode" autocomplete="off" placeholder="请输入设备特征码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="经纬度" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lng">
                    <el-input clearable v-model.number="deviceForm.lng" autocomplete="off" placeholder="经度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lat">
                    <el-input clearable v-model.number="deviceForm.lat" autocomplete="off" placeholder="纬度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3" :offset="0" style="padding-left:0">
                  <el-form-item>
                    <el-tooltip class="item" effect="dark" content="定位" placement="top">
                      <el-button type="text" style="font-size:16px" size="default" @click="open('map')" icon="el-icon-location"></el-button>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="基站特征码" prop="stationCode" :label-width="formLabelWidth">
              <el-input v-model="deviceForm.stationCode" autocomplete="off" placeholder="请输入基站特征码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="安装时间" prop="installTime" :label-width="formLabelWidth2">
              <el-date-picker v-model="deviceForm.installTime" type="datetime" placeholder="选择安装时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="最近数据通讯时间" prop="lastContactTime" :label-width="formLabelWidth">
              <el-date-picker v-model="deviceForm.lastContactTime" type="datetime" size="normal" placeholder="选择最近数据通讯时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="权重" prop="weight" :label-width="formLabelWidth2">
              <el-input-number v-model="deviceForm.weight" size="normal">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="联系电话" prop="contactPhone" :label-width="formLabelWidth">
              <el-input v-model="deviceForm.contactPhone" autocomplete="off" placeholder="请输入联系电话" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="描述" prop="description" :label-width="formLabelWidth2">
              <el-input type="textarea" v-model="deviceForm.description" autocomplete="off" placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialog1 = false">取 消</el-button>
        <el-button type="primary" @click="addDevice" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateDevice" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <el-dialog class="dialog" :visible.sync="dialog2" width="50%" top="5%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom" ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
          <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmGeolocation,
    BmMarker
  },
  data () {
    return {
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      addressKeyword: '',
      currentPage: 1,
      formLabelWidth: '130px',
      formLabelWidth2: '120px',
      total: 0,
      deviceList: [],
      areaList: [],
      deviceId: 0, // 单位ID
      deviceForm: {
        deviceName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        policePhone: '',
        remark: ''
      }, // 建筑表单
      deviceFormCopy: {},
      typeList: [],
      fireUnitList: [],
      rules: {
        typeId: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        equipmentCode: [
          { required: true, message: '请输入设备特征码', trigger: 'blur' }
        ],
        stationCode: [
          { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的基站特征码', trigger: 'blur' }
        ],
        contactPhone: [
          { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请输入正确的的手机号', trigger: 'blur' }
        ]
      },
      searchForm: {
      },
      dialog1: false,
      dialog2: false,
      optFlag: false,
      isLoad: false,
      unitOptions: [],
      buildingOptions: [],
      areaCodes: [],
      loading: false,
      loading1: false
    }
  },
  created () {
    this.initTable()
    this.getTypeList()
    // this.getUnitList()
    // this.getBuildingList()
    // this.getFireUnitList()
    this.deviceFormCopy = JSON.stringify(this.deviceForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.status = this.searchForm.status
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading1 = true
      const { data: result } = await this.$axios.get('/equipment/list', { params: data })
      if (result.code === 200) {
        this.loading1 = false
        this.deviceList = result.data.result
        this.total = result.data.total
      } else {
        this.loading1 = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteDevice (id) {
      const confirm = await this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/equipment/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateDevice () {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/equipment/edit/${this.deviceId}`, this.deviceForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.dialog1 = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增培训
    async addDevice () {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          if (!this.deviceForm.unitId) {
            this.$message.error('请选择所属单位')
          }
          if (!this.deviceForm.installId) {
            this.$message.error('请选择安装建筑物')
          }
          const { data: result } = await this.$axios.post('/equipment/add', this.deviceForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.dialog1 = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 获取位置
    async getPosition () {
      console.log(this.location)
      this.deviceForm.lng = this.location.lng
      this.deviceForm.lat = this.location.lat
      this.dialog2 = false
    },
    // 设置位置
    async setPosition (e) {
      this.location.lng = e.point.lng
      this.location.lat = e.point.lat
    },
    // 地图初始化
    handler ({ BMap, map }) {
      const that = this
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition(function (r) {
        that.location.lng = r.point.lng
        that.location.lat = r.point.lat
      })
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.dialog1 = true
          this.$nextTick(() => {
            this.$refs.deviceForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.dialog1 = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.deviceForm.clearValidate()
          })
          this.deviceForm = JSON.parse(JSON.stringify(row))
          this.deviceId = row.id
          break
        case 'map':
          this.dialog2 = true
          break
      }
    },
    // // 查找建筑物
    // async getBuildingList () {
    //   const { data: result } = await this.$axios.get('/building/listAllBuilding')
    //   if (result.code === 200) {
    //     this.buildingList = result.data
    //   }
    // },
    // // 查找单位
    // async getUnitList () {
    //   const { data: result } = await this.$axios.get('/unit/listAllUnit')
    //   if (result.code === 200) {
    //     this.unitList = result.data
    //   }
    // },
    // 查找建筑结构
    async getFireUnitList () {
      const { data: result } = await this.$axios.get('/organization/list', { params: { parentId: 1 } })
      if (result.code === 200) {
        this.fireUnitList = result.data
      }
    },
    // 查找设备类型
    async getTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 关闭弹窗清空表单
    clear () {
      this.deviceForm = JSON.parse(this.deviceFormCopy)
      // this.$refs.deviceForm.resetFileds()
      this.deviceForm = {
        deviceName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        remark: ''
      }
      this.$refs.deviceForm.clearValidate()
    },
    // querySearch (queryString, cb) {
    //   const unitList = this.unitList
    //   var results = queryString ? unitList.filter(this.createFilter(queryString)) : unitList
    //   // 调用 callback 返回建议列表的数据
    //   cb(results)
    // },
    // querySearch1 (queryString, cb) {
    //   const buildingList = this.buildingList
    //   var results = queryString ? buildingList.filter(this.createFilter1(queryString)) : buildingList
    //   // 调用 callback 返回建议列表的数据
    //   cb(results)
    // },
    // createFilter (queryString) {
    //   return (state) => {
    //     return (state.unitName.indexOf(queryString) === 0)
    //   }
    // },
    // createFilter1 (queryString) {
    //   return (state) => {
    //     return (state.buildingName.indexOf(queryString) === 0)
    //   }
    // },
    // handleSelect (item) {
    //   this.$set(this.deviceForm, 'unitId', item.id)
    // },
    // handleSelect1 (item) {
    //   this.$set(this.deviceForm, 'installId', item.id)
    // },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    async remoteMethod2 (query) {
      const { data: result } = await this.$axios.get('/building/listAllBuilding', { params: { buildingName: query } })
      if (result.code === 200) {
        this.buildingOptions = result.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
</style>
